/* IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sonsie+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chango&family=Dosis:wght@700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poller+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chango&family=Cinzel+Decorative:wght@900&family=Gravitas+One&family=Rubik+Mono+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400&family=Prompt&family=Roboto&family=Roboto+Condensed:wght@300&family=Syncopate:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@600&display=swap');

/* GLOBAL ATTRIBUTES */
a:link, a:visited, a:hover, a:active {
    text-decoration: none;
}
ul {
    list-style: none;
}
* {
    
}

/* COLORS:
    light green: #6DAE47
    middle green: #5C913B
    dark green: #4A742F
*/

/* NAVBAR */

.app-navbar {
    /* border-top: 3px solid #6DAE47; */
    /* border-bottom: 2px solid rgb(213, 213, 213); */
    margin-right: 0px;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: white;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
    /* background-image: linear-gradient(to top, #d4d4d4 1%, #eeeeec 30%, #efeeec 75%, #e9e9e7 100%); */
}
.logo {
    align-items: center;
    display: flex;
    height: 60px;
    padding: 10px;
    margin-left: 20px;
}
/*.logo:hover {
    background-color: rgb(226, 243, 255);
    border-radius: 10px;
}*/
.logoImage {
    margin-right: 0;
    height: 40px;
}
a.logoText {
    color: #5C913B;
    font-size: 22pt;
    font-style: italic;
    text-decoration: none;
    padding-bottom: 5px;
}
a.logoText:hover {
    color: #5C913B;
}
.navbar {
    margin-bottom: 0;
}
ul.login {
    margin-bottom: 0;
}
nav {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    height: 60px;
    padding: 0;
}
nav ul li {
    list-style-type: none;
    display: inline-block;
    padding-right: 0;
    margin: 0px 70px;
    font-size: 18px;
    font-weight: 400;
}
nav ul li a {
    color: black;
    text-decoration: none;
}
.login li {
    list-style-type: none;
    display: inline-block;
    margin: 0px 10px;
}
nav ul li a:hover {
    color: #6DAE47;
}
.loginButton {
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
    outline: none;
    background-color: transparent;
    border: 1px solid black;
    width: 110px;
    height: 40px;
    color: black;
    text-decoration: none;
    font-size: 16px;
    border-radius: 20px;
}
.loginButton:hover {
    border: 1px solid #6DAE47;
    color: white;
    transition: 0.1s;
    background-color: #6DAE47;
}
span.navLinks {
    padding-left: 7px;
}
.navLinks {
    margin-bottom: 0px;
}
.navbar li a {
    display: flex;
    align-items: center;
}
.navbar li a:hover {
    border-bottom: 2px solid #6DAE47;
}

@media(max-width: 1400px) {
    nav ul li {
        margin: 0px 50px;
    }
}
@media(max-width: 1150px) {
    nav ul li {
        margin: 0px 30px;
    }
}

/* BURGER MENU */

.burger-navbar {
    display: none;
}
.closeIcon {
    display: none;
}
.menuIcon {
    display: none;
    background: transparent;
    color: black;
}

@media(max-width: 1000px) {
    ul.navbar {
        display: none;
    }
    ul.login {
        display: none;
    }
    .burger-navbar {
        display: block;
        z-index: 10;
        position: absolute;
        top: 0;
        right: -300px;
        height: 100vh;
        width: 300px;
        background: #6cac45;
        text-align: left;
        transition: 0.5s;
    }
    .menuIcon {
        margin-right: 10px;
        display: block;
        border: none;
        color: #5C913B;
    }
    .closeIcon {
        margin-right: 10px;
        margin-top: 13px;
        display: block;
        float: right;
        border: none;
        background: transparent;
        color: white;
    }
    .burgerList {
        padding-top: 35px;
    }
    .burgerList ul {
        margin: 0;
        padding: 0;
    }
    .burgerList ul li {
        margin-top: 33px;
    }
    .burgerList ul li a {
        color: white;
        font-size: 19px;
        display: flex;
        align-items: center;
        margin-right: 40px;
        padding-bottom: 1px;
    }
    .burgerList ul li a:hover {
        color: white;
        border-bottom: 2px solid white;
    }
}

/* FOOTER */

.footer {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    
}
.footerTitle {
    font-family: 'Arial', sans-serif;
    font-weight: 700;
}
.footerText {
    font-size: 15px;
    margin: 0 5px;
    padding: 20px;
}
.footerLinks{
    margin-left: 200px ;
}
.footer-socials li a {
    padding-left: 10px;
    padding-right: 10px;
    color: #198754;
}
.footer-socials li a:hover {
    color: #1da364;
}

/* HOME PAGE */

.homePage {
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
    overflow-x: hidden;
}
.homeContainer {
    height: 100vh;
    width: 100%;
}
.homeContainer1 {
    background-image: /*linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0) 160px),*/ url('../public/background/Dog_Cropped3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: calc(100vh);
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.15);
    display: flex;
}
.homeContainer2 {
    background-color: white;
    height: auto;
    width: 100%;
}
.homeContainer3 {
    background-color: white;
    background-size: cover;
    height: auto;
    width: 100%;
    display: flex;
    padding-top: 90px;
    padding-bottom: 50px;
}
.homeContainer4 {
    background-color: white;
    height: 100vh;
    width: 100%;
}
.homeContent {
    max-width: 1080px;
    margin: auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.homeContent1 {
    max-width: 550px;
    margin-left: 18vw;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.homeColumnTop {
    width: auto;
    margin: auto;

    text-align: center;
    border-radius: 30px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}
.largeTitle {
    width: 550px;
    font-size: 5em;
    padding-bottom: 10px;
    color: white;
    font-weight: 1000;
    text-shadow: 6px 6px 0px rgba(0,0,0,0.2);
}
.smallTitle {
    width: 300px;
    font-size: 4em;
    color: white;
    font-weight: 1000;
    display: none;
    text-shadow: 6px 6px 0px rgba(0,0,0,0.2);
}
.largeh2 {
    font-size: 40px;
    color: white;
    font-weight: 700;
    padding-bottom: 10px;
    font-family: "Arial";
}
.smallh2 {
    font-size: 25px;
    color: white;
    font-weight: 1000;
    font-family: "Arial";
    display: none;
}
.homeColumnTop p {
    font-size: 25px;
    color: white;
    font-family: "Arial";
}
.homeButton {
    margin-top: 20px;
    width: 200px;
    height: 50px;
    background-size: 200%;
    background-color: #87C65A;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.homeButton:hover {
    background-color: #80b958;
}
.homeButton a {
    color: white;
}
.homeButton a:hover {
    color: white;
}
.homeContent2 {
    align-items: center;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 1em;
}
.homeContent3 {
    align-items: center;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
}
.homeContent4 {
    align-items: center;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
}
.homeContent2 p {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
    padding-top: 0;
}
.sectionHeader {
    padding-top: 70px;
    padding-bottom: 10px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    display: flex;
    font-family: 'Arial', sans-serif;
}
.sectionHeader h1 {
    color: transparent;
    background-color: #009432;
    background-image: linear-gradient(45deg,#009432,#C4E538);
    background-size: 100%;
    -webkit-background-clip: text;
    font-size: 50px;
    font-weight: bold;
}
.sectionHeader2 {
    padding-top: 30px;
    padding-bottom: 50px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    display: flex;
    font-family: 'Arial', sans-serif;
}
.sectionHeader2 h1 {
    color: transparent;
    background-color: #009432;
    background-image: linear-gradient(45deg,#009432,#C4E538);
    background-size: 100%;
    -webkit-background-clip: text;
    font-size: 50px;
    font-weight: bold;
}
.homeCard {
    text-align: center;
    width: 300px;
    height: 250px;
    background: white;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    margin: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    transition: 0.5s;
}
.homeCard:hover {
    transform: translateY(-20px);
}
.cardTitle {
    font-size: 27px;
}
.homeCard p {
    padding: 15px;
}
.nfcLogo {
    height: 250px;
}
.homeAboutButton {
    margin-top: 20px;
    width: 200px;
    height: 50px;
    background-size: 200%;
    background-color: #87C65A;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.homeAboutButton:hover {
    background-color: #80b958;
}
a.anchorJump {
    height: 0;
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
}

@media(max-width: 1000px) {
    .homePage {
        margin-top: 60px;
    }
    .largeTitle {
        display: none;
    }
    .smallTitle {
        display: block;
    }
    .smallTitleBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }
    .largeh2 {
        display: none;
    }
    .smallh2 {
        display: block;
    }
    .homeColumnTop p {
        font-size: 20px;
        padding-top: 10px;
        max-width: 700px;
    }
    .homeColumnTop {
        padding: 30px;
    }
    .homeContent1 {
        max-width: 1080px;
        margin: auto;
        padding: 0;
        padding-bottom: 4em;
        display: flex;
        justify-content: space-between;
    }
    .homeColumnTop {
        margin-top: 3em;
        text-align: center;
        max-width: 1080px;
    }
    .homeContent2 {
        flex-direction: column;
        margin: 0;
        padding: 0;
        padding-bottom: 2em;
        text-align: center;
    }
    .homeContent2 p {
        padding-left: 50px;
        padding-right: 50px;
    }
    .homeContent3 {
        flex-direction: column;
        margin: 0;
        padding: 0;
        padding-bottom: 7em;
        text-align: center;
    }
    .homeCard {
        width: 300px;
    }
    .homeContainer1 {
        height: auto;
        box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);

    }
    .homeContainer2 {
        height: auto;
    }
    .homeContainer3 {
        flex-direction: column;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    .homeContent4 h1 {
        padding-left: 100px;
        padding-right: 100px;
    }
    .bottomMessage {
        padding-right: 0;
    } 
}
@media(max-width: 590px) {
    .homeContainer1 {
        background-position: center;
    }
}

/* TAGS PAGE */

.tagsPage {
    margin-top: 70px;
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
}
.tagsContainer {
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 70px);
    width: 100%;
    height: auto;
}
.tagsContent {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
}
.tagsHeader {
    padding-top: 40px;
    margin-bottom: 30px;
    text-align: center;
    font-style: italic;
}

/* TAGS ID PAGE */

.tagsIdPage {
    margin-top: 0;
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
}
.tagsIdContainer {
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 70px);
    width: 100%;
    height: auto;
}
.tagsIdContent {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    height: auto;
    width: 490px;
}
.tagsIdHeader {
    padding-top: 5px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 20px;
}
.dogName {
    text-align: center;
    margin-bottom: 10px;
    font-weight: normal;
}
.dogImage {
    height: 450px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.blueText {
    color: rgb(0, 132, 255);
}
.redText {
    color: red;
    font-weight: bold;
}
.dogNameText {
    font-size: 35px;
    font-weight: normal;
}
.tagsIdContent2 {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 25px;
    padding-top: 10px;
    height: auto;
    width: 490px;
}
.dogNameText2 {
    font-size: 36px;
    font-weight: normal;
}
.tagsIdContent3 {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 25px;
    padding-top: 40px;
    height: auto;
    width: 490px;
}
.dogNameText3 {
    font-size: 19px;
    font-weight: normal;
}
.tagsIdContent4 {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 25px;
    height: auto;
    width: 490px;
}

/* STORE PAGE */

.storePage {
    margin-top: 70px;
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
}
.storeContainer {
    width: 100%;
    height: auto;
    background-color: rgb(217, 255, 217);
}
.storeContent {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 3em 2em;
}

/* ABOUT PAGE */

.aboutPage {
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
    overflow-x: hidden;
}
.aboutContainer {
    height: 100vh;
    width: 100%;
}
.aboutContainer1 {
    background-image: /*linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0) 160px),*/ url('../public/background/groupPic.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: calc(100vh);
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.15);
    display: flex;
    align-items: center;
    min-height: 750px;
}
.aboutContainer2 {
    background-color: white;
    background-size: cover;
    height: auto;
    width: 100%;
    display: flex;
    padding-top: 70px;
    padding-bottom: 30px;
}
.aboutContainer4 {
    height: auto;
}
.aboutContent {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
}
.aboutContent1 {
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: center;
}
.aboutBox {
    margin-top: 60px;
    max-width: 900px;
    max-height: 700px;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 70px 0;
    padding-bottom: 20px;
}
.carousel-item h1 {
    font-size: 26px;
}
.carousel-item p {
    padding-top: 20px;
    font-size: 18px;
}
.carousel-control-next,
.carousel-indicators,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    margin-bottom: 50px;
}
.carousel-indicators {
    z-index: 1;
}
.carousel-inner {
    padding: 0 120px;
    padding-bottom: 100px;
    min-height: 425px;
}
.aboutBoxButton {
    padding-top: 40px;
    display: flex;
    justify-content: center;
}
.aboutBoxButton button {
    border: none;
    height: 20px;
    background-color: transparent;
}
.aboutBoxButton button :hover {
    border: none;
    background-color: rgb(216, 216, 216);
    border-radius: 20px;
}
.aboutBoxHeader {
    padding-bottom: 50px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    display: flex;
    font-family: 'Arial', sans-serif;
}
.aboutBoxHeader h1 {
    color: transparent;
    background-color: #009432;
    background-image: linear-gradient(45deg,#009432,#C4E538);
    background-size: 100%;
    -webkit-background-clip: text;
    font-size: 50px;
}
.aboutContent3 {
    display: block;
    padding: 1em 2em;
    text-align: center;
}
.aboutContent a{
    color:black;
}
.aboutContent :hover{
    color:rgb(5, 138, 0)
}
.aboutContent3 a{
    color:black;
}
.aboutContent3 :hover{
    color:black;
}
.aboutImage1 {
    height: 250px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.aboutHeader {
    padding-top: 70px;
    padding-bottom: 30px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    display: flex;
    font-family: 'Arial', sans-serif;
}
.aboutHeader h1 {
    color: transparent;
    background-color: #009432;
    background-image: linear-gradient(45deg,#009432,#C4E538);
    background-size: 100%;
    -webkit-background-clip: text;
    font-size: 50px;
}
.aboutHeader2 {
    padding-bottom: 50px;
}
.aboutContent p {
    padding-left: 60px;
    padding-right: 20px;
    font-size: 18px;
    padding-top: 0;
}
.aboutContent3 p {
    padding: 0;
    font-size: 22px;
}
.nameText {
    margin-top: 20px;
    font-size: 24px;
    font-weight: normal;
    padding-left: 5px;
}
.descriptionText {
    margin-top: 0px;
    font-size: 16px;
    font-style: italic;
    font-weight: normal;
    padding-left: 5px;
}
.pfpColumn {
    transition: 0.5s;
}
.pfpColumn:hover {
    transform: translateY(-10px);
}
.aboutContactButton {
    margin-top: 20px;
    width: 200px;
    height: 50px;
    background-size: 200%;
    background-color: #87C65A;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.aboutContactButton:hover {
    background-color: #80b958;
}
.aboutContent2 a :hover {
    color: white;
}
.aboutContainer5 {
    background-image: /*linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0) 160px),*/ url('../public/background/groupPic.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.15);
    display: none;
}
.aboutGroupImage {
    opacity: 0;
    width: 100vw;
}

@media (max-width: 1000px) {
    .aboutContent {
        flex-direction: column;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    .pfpColumn {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .aboutContainer2 {
        flex-direction: column;
        margin: 0;
        text-align: center;
    }
    .aboutContent2 {
        flex-direction: column;
        margin: 0;
        padding-bottom: 2em;
        text-align: center;
    }
    .aboutContent3 {
        padding: 1em 2em;
    }
}
@media(max-width: 700px) {
    .aboutContent1 {
        
    }
    .aboutContainer1 {
        box-shadow: none;
        background-image: none;
        background-color: white;
    }
    .aboutContainer5 {
        display: block;
    }
    .carousel-control-next,
    .carousel-control-prev /*, .carousel-indicators */ {
    margin: 0;
    position: absolute;
    bottom: 0;
    }
    .carousel-item {
        text-align: center;
    }
    .carousel-inner {
        padding: 0 60px;
        padding-bottom: 60px;
    }
    .aboutContainer1 {
        height: auto;
    }
    .aboutContent1 {
        height: auto;
    }
    .carousel-item h1 {
        font-size: 20px;
    }
    .carousel-item p {
        padding-top: 20px;
        font-size: 14px;
    }
}

/* LOGIN PAGE */

.loginPage {
    margin-top: 70px;
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
    overflow-x: hidden;
}
.loginContainer {
    width: 100%;
    height: auto;
    background-color: rgb(217, 255, 217);
}
.loginContent {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 3em 2em;
}

/* INFO PAGE */

.productPage {
    margin-top: 60px;
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
    overflow-x: hidden;
}
.productContainer {
    height: 100vh;
    width: 100%;
}
.productContainer1 {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: calc(100vh + 60px);
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.15);
}
.productContainer2 {
    background-color: white;
    background-size: cover;
    height: auto;
    width: 100%;
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
}
.productContent {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding-bottom: 40px;
    /*font-family: 'Roboto', sans-serif;*/
}
.productContainer6 {
    background-image: /*linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0) 160px),*/ url('../public/background/background (3)2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: calc(100vh - 360px);
    display: flex;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.15);
}
.productContent5 {
    display: flex;
    padding-top: 30px;
    padding-bottom: 0px;
}
.productContent8{
    width: 1080px;
    display: flex;
    align-items: center;
    margin: 0;
}
.productQuote {
    max-width: 600px;
    text-align: center;
}
.productQuote h1{
    font-size: 40px;
    font-weight: 700;
    color: white;
}
.productContent6 {
    padding-bottom: 90px;
}
.productIconBox {
    justify-content: center;
    max-width: 500px;
    text-align: center;
}
.productIcon {
    height: 150px;
}
.productText {
    font-size: 30px;
}
.productContent1 {
    max-width: 600px;
    margin-left: 18vw;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.productContainer3 {
    height: auto;
}
.productColumnTop {
    width: auto;
    margin: auto;
    margin-top: 40vh;
    text-align: center;
    border-radius: 30px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
}
.productColumn {
    max-width: 300px;
    text-align: center;
}
.productColumn h1 {
    font-size: 25px;
}
.productColumnTop p {
    font-size: 40px;
    color: white;
    font-family: "Arial";
}
.productContent2 {
    padding-bottom: 40px;
}
.producth2 {
    font-size: 70px;
    color: #87C65A;
    font-weight: 700;
    padding-bottom: 10px;
    font-family: "Arial";
    text-shadow: 2px 3px 5px rgba(0,0,0,0.5);
}
.productParagraph {
    padding-left: auto;
    padding-right: auto;
    font-size: 22px;
    padding-top: 0;
}
.productParagraph h1 {
    font-size: 34px;
    padding-left: 20px;
}
.productParagraph p {
    font-size: 22px;
    padding-right: 20px;
    padding-left: 20px;
}
.productParagraph2 {
    padding-left: auto;
    padding-right: auto;
    font-size: 22px;
    padding-top: 0;
}
.productParagraph2 h1 {
    font-size: 34px;
    padding-left: 20px;
}
.productParagraph2 p {
    font-size: 22px;
    padding-right: 20px;
    padding-left: 20px;
}
.productHeader {
    padding-top: 70px;
    padding-bottom: 50px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    display: flex;
    font-family: 'Arial', sans-serif;
}
.productHeader h1 {
    color: transparent;
    background-color: #009432;
    background-image: linear-gradient(45deg,#009432,#C4E538);
    background-size: 100%;
    -webkit-background-clip: text;
    font-size: 50px;
    font-weight: bold;
}
.productImage {
    height: 250px;
    margin: 25px;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}
.productImage2 {
    height: 250px;
    margin: 25px;
    border-radius: 20px;
}
.productAboutButton {
    margin-top: 20px;
    width: 200px;
    height: 50px;
    background-size: 200%;
    background-color: #87C65A;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.productAboutButton:hover {
    background-color: #80b958;
}


@media(max-width: 1000px) {
    .productContent {
        flex-direction: column;
        margin: 0;
        padding: 0;
        padding-bottom: 2em;
        text-align: center;
    }
    .productContainer6 {
        background-position: center;
    }
    .productQuote h1 {
        font-size: 30px;
    }
    .productContent8 {
        padding-left: 20px;
        padding-right: 20px;
        justify-content: center;
    }
    .productContent p {
        font-size: 18px;
        padding-left: 50px;
        padding-right: 50px;
    }
    .productParagraph h1 {
        font-size: 24px;
        padding-left: 20px;
        padding-right: 20px;
    } 
    .productParagraph2 h1 {
        font-size: 24px;
        padding-left: 20px;
        padding-right: 20px;
    } 
    .productContainer2 {
        flex-direction: column;
        margin: 0;
        padding-top: 3em;
        text-align: center;
    }
    .productContent2 {
        flex-direction: column;
        margin: 0;
        padding-bottom: 2em;
        text-align: center;
    }
    .productContent7 {
        flex-direction: column;
    }
    .productContent6 {
        flex-direction: column;
    }
}

/* CONTACT PAGE */

.contactPage {
    margin-top: 70px;
    height: auto;
    width: 100%;
    background-size: 100vw;
    background-repeat: no-repeat;
    overflow-x: hidden;
}
.contactContainer {
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100vh - 70px);
    width: 100%;
    height: auto;
}
.contactContent {
    max-width: 875px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}
.contactHeader {
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    display: flex;
    font-family: 'Arial', sans-serif;
}
.contactHeader h1 {
    color: transparent;
    background-color: #009432;
    background-image: linear-gradient(45deg,#009432,#C4E538);
    background-size: 100%;
    -webkit-background-clip: text;
    font-size: 50px;
}
.contactForm {
    align-items: center;
}
.formLabel {
    font-size: 18px;
    padding-bottom: 5px;
}
.formInput {
    height: 35px;
    width: 40vmax;
    max-width: 900px;
    padding-left: 5px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.questionInput {
    height: 200px;
    width: 40vmax;
    max-height: 300px;
    max-width: 900px;
    padding-left: 5px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.formRow {
    padding-bottom: 20px;
}
.formRow2 {
    padding-top: 2em;
    padding-bottom: 2em;
    justify-content: center;
    display: flex;
}
.contactButton {
    margin-top: 20px;
    width: 200px;
    height: 50px;
    background-size: 200%;
    background-color: #87C65A;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 20px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.contactButton:hover {
    background-color: #80b958;
}


/* ALL HEADERS */
.globalHeader h1 {
    font-weight: bold;
}
@media(max-width: 1000px) {
    .globalHeader h1 {
        font-size: 35px;
    }
}

/* NEW FOOTER */

footer {
    background-color: #87C65A;
    height: 400px;
}